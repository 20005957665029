import React from 'react';
import { createRoot } from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { firebaseConfig } from './config';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { CssBaseline } from '@mui/material';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ServiceWorkerWrapper />
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <CssBaseline />
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
